#support-search {
  & .bg-white {
    background-color: #fff !important;
  }
}

.support {
  &__search {

    & .form-group {
      margin-bottom: 0;
    }

    & .form-control {
        padding-right: 2.375rem;
        padding-left: 1.875rem;
        height: 100%;

        border: 1px solid $service_filter_input_border;

        &:focus {
          border-color: 1px solid #ced4da;
          box-shadow: none;
        }

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: $service_filter_input_placeholder;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: $service_filter_input_placeholder;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: $service_filter_input_placeholder;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: $service_filter_input_placeholder;
        }
    }

    & .form-control-feedback {
        position: absolute;
        right: 0;
        z-index: 2;
        display: block;

        padding: 1.375rem 1.25rem;
        line-height: 100%;

        text-align: center;
        pointer-events: none;

        color: #000;
    }

    &--results {
      position: absolute;
      display: none;

      z-index: 10;

      background-color: $service_filter_result_bg;
      color: $service_filter_result_color;
      width: 100%;

      padding: 1.75rem;

      top: 62px;
      left: 0;

      & h4, a {
        color: $service_filter_headlines;
      }

      & img {
        max-height: 3.75rem;
      }
    }
  }

  &__textlink {
    @include rfs(1rem, true);
  }

}
