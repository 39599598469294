.fs-8 {
  font-size: 8px !important;
}

.fs-9 {
  font-size: 9px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}
