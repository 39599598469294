.productimg-carousel {

  .carousel-item {
    min-height: 12rem;

    @include media-breakpoint-up(lg) {
      max-height: 25rem;
      // #1133 - lg size, minHeight
      min-height: 25rem;
    }

    img {
      min-height: 12rem;
      box-sizing: content-box;
      padding-top: 0;

      width: 100%;
      height: auto;

      @include media-breakpoint-up(lg) {
        max-height: 25rem;
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }

  &__controls {

    &--left,
    &--right,
    &--middle {
      left: 0;
      right: 0;
      margin-left: 25%;
      margin-right: 25%;
      position: absolute;
      bottom: 0;

      & > a {
        margin-right: .5rem !important;
      }
    }

    @include media-breakpoint-up(lg) {
      &--left,
      &--right,
      &--middle {
        margin-left: 0;
        margin-right: 0;
        left: auto;
        right: auto;
      }

      &--middle {
        right: 35%;
      }

      &--left {
        left: 0;

        & > a {
          margin-right: .5rem !important;
        }
      }

      &--right {
        right: 0;

        & > a {
          margin-left: .5rem !important;
        }
      }
    }
  }
}
