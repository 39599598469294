//-------------------------
// 🎨 Colors
//-------------------------

// UI Color Scheme
$color__black: #000 !default;
$color__dark-grey: #3C3C3B !default;
$color__middle-grey: #545454 !default;
$color__grey: #B2B2B2 !default;
$color__light-grey: #EDEDED !default;
$color__white: #fff !default;

// UI Main Colors
$color__blue: #008bc5 !default;
$color__blue-dark: #005082 !default;
$color__dark-blue: $color__blue-dark !default; // Workaround for mixed naming scheme

$color__orange: #ed6b06 !default;
$color__orange-dark: #BE3200 !default;

$color__yellow: #FFD700 !default;
$color__yellow-dark: #FFAF00 !default;

$color__red: #BE1441 !default;
$color__red-dark: #96193C !default;

$color__violet: #3C5AA0 !default;
$color__violet-dark: #1E417D !default;

$color__green: #C8D200 !default;
$color__green-dark: #A5AA1E !default;
