// General styles
header {

  & .navbar-toggler:not(.collapsed) i {
    color: theme-color('primary') !important;
  }

  &.header-white {

    background-color: $color__white !important;
    border-bottom: none !important;

    & .navigation__mobile--meta .nav-item .navigation__meta--icon {
      color: $mobile-icon-color !important;
    }

    & .header-switch i, & .header-switch span {
      color: $mobile-icon-color !important;
    }

    @include media-breakpoint-up(lg) {
      & #navigation-main > .nav-item > a, .navigation-divider:after {
        color: theme-color('primary') !important;
      }

      & .header-switch i, & .header-switch span {
        color: theme-color('primary') !important;
      }
    }
  }

  position: fixed !important;

  height: $navigation_height_responsive;
  background-color: transparent;

  -webkit-transition: background-color .5s ease-out;
  transition: background-color .5s ease-out;

  @include media-breakpoint-up(lg) {
    height: $navigation_height;

    @if variable-exists(fontevo) {
      padding: 1.25rem 0 0 0 !important;
    }
  }

  &.fixed-top {
    z-index: 9998;
  }

  &.navbar {
    padding: 0;

    @include media-breakpoint-up(lg) {

      @if variable-exists(fontevo) {
        padding: 1.25rem 0 0 0 !important;
      }
    }
  }

  .container {
    height: 100%;
  }

  .logo-born {

    display: none;
    position: absolute;

    @if variable-exists(fontevo) {
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
      top: 5.9375rem;
      width: 7.27375rem;
    } @else {
      top: 4.125rem;
      width: 8rem;
    }

    margin-left: 2.25rem;
    background-color: #ffffff;

    @include media-breakpoint-up(lg) {

      margin-left: 0;

      @if variable-exists(fontevo) {
        top: 10rem;
        width: 12.5rem;
      } @else {
        top: 6.5rem;
        width: 14.375rem;
      }
    }

    & img {

      width: 100%;
      height: auto;

      display: block;
      position: relative;
    }
  }

  .navbar-brand {
    padding: 0;
    margin-left: 2.25rem;

    @if variable-exists(fontevo) {
      position: absolute;

      top: 0;
      z-index: 9999;

      background-color: #ffffff;
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);

      padding: 1.45rem;
    }

    @include media-breakpoint-up(lg) {
      margin-left: 0;

      @if variable-exists(fontevo) {
        padding: 2.5rem;
      }
    }

    img {
      width: auto;

      @if variable-exists(fontevo) {
        width: 70px;
        display: block;
        position: relative;
      } @else {
        height: 45px;
      }

      @include media-breakpoint-up(lg) {
        @if variable-exists(fontevo) {
          width: 120px;
        } @else {
          height: 80px;
        }
      }
    }
  }

  .navbar-collapse {
    position:absolute;
    top: $navigation_height_responsive;
    width: 100%;

    @include media-breakpoint-up(lg) {
      position: inherit;
      top: inherit;
      width: inherit;
      height: 100%;
    }
  }
}

.header {

  &-switch {

    &--fr {
      .header__switch--icon {
        input + span {
          &:before {
            @include media-breakpoint-up(lg) {
              left: 85px;
            }
          }
          &:after {
            @include media-breakpoint-up(lg) {
              left: 85px;
            }
          }
        }
      }
    }

    display: none;

    left: 12rem;
    position: absolute;
    top: calc(#{$navigation_height_responsive} / 3.5);

    @include media-breakpoint-up(lg) {

      display: none;

      @if variable-exists(fontevo) {
        margin-left: 17.8125rem;
      } @else {
        margin-left: 2.8125rem;
      }


      position: relative;
      left: 0;
      top: 0;
    }

    &-icon {

      position: relative;
      padding-left: 0;
      line-height: 30px;

      & > span {

        font-size: 0;

        @include media-breakpoint-up(lg) {
          font-size: 1rem;
        }

        @if variable-exists(fontevo) {
          color: $color__white;
        } @else {
          color: theme-color('secondary');
        }

        & > i {
          font-size: 1.625rem;
          padding-right: 0.5rem;
        }
      }

      input {

          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 0%;
          height: 0%;
          margin: 0;
          cursor: pointer;
          opacity: 0;
          filter: alpha(opacity=0);
      }

      input + span {

          cursor: pointer;
          user-select: none;
          height: 30px;
          margin-left: 55px;
          display: block;

          @include media-breakpoint-up(lg) {
            height: 30px;
            margin-left: 70px;
          }

          &:before {

              content: '';
              position: absolute;
              left: 25px;
              display: inline-block;
              height: 30px;
              width: 50px;
              background: theme-color('secondary');
              border: solid 1px theme-color('secondary');
              transition: background 0.3s ease-in-out, border-color 0.3s ease-in-out;
              border-radius: 15px;

              @include media-breakpoint-up(lg) {
                border-radius: 15px;
                height: 30px;
                width: 50px;
                left: 115px;
              }
          }

          &:after {

              width: 28px;
              height: 28px;

              margin-top: 1px;
              margin-left: 1px;
              border-radius: 50%;

              position: absolute;
              left: 25px;
              top: 0;

              display: block;
              background: #FFF;
              transition: margin-left 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
              text-align: center;
              font-weight: bold;
              content: '';
              box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 0 5px rgba(0, 0, 0, 0.05);

              @include media-breakpoint-up(lg) {
                width: 28px;
                height: 28px;

                left: 115px;
              }
          }
      }

      input {
          &:checked + span {
              &:after {
                  content: '';
                  margin-left: 21px;
                  box-shadow: none;
              }

              &:before {
                  background-color: theme-color('ci-green');
                  border-color: theme-color('ci-green');
                  transition: background 0.3s ease-in-out, border-color 0.3s ease-in-out;
              }
          }
      }
    }
  }
}

.navigation {

  &-meta-icon {
    font-size: 1.75rem;
  }

  &-mobile-meta {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}
