/* SECTIONS */

.section {

  // Spacing
  // xl: 5.75rem
  $space_xxl: 13.75rem;
  // xl: 5.75rem
  $space_xl--xl: 5.75rem;
  $space_xl--lg: 3.75rem;
  $space_xl--sm: 2.75rem;
  // lg: 3.75rem
  $space_lg--xl: 3.75rem;
  $space_lg--lg: 2.75rem;
  $space_lg--sm: 1.85rem;
  // md: 2.25rem
  $space_md--xl: 2.25rem;
  $space_md--lg: 2rem;
  $space_md--sm: 1.55rem;
  // sm: 1.75rem
  $space_sm--xl: 1.75rem;
  $space_sm--lg: 1.5rem;
  $space_sm--sm: 1.25rem;
  // xsm: 1.25rem
  $space_xs--lg: 1.25rem;
  $space_xs--sm: 1.25rem;

  &__border {

    &--one {
      border: 1px solid $white;
    }

    &--two {
      border: 2px solid $white;
    }
  }

  &__img {
    width: 100%;
  }

  &__my {

    &--xl {
      margin: $space_xl--sm 0;

      @include media-breakpoint-up(lg) {
        margin: $space_xl--lg 0;
      }

      @include media-breakpoint-up(xl) {
        margin: $space_xl--xl 0;
      }
    }

    &--lg {
      margin: $space_lg--sm 0;

      @include media-breakpoint-up(lg) {
        margin: $space_lg--lg 0;
      }

      @include media-breakpoint-up(xl) {
        margin: $space_lg--xl 0;
      }
    }

    &--md {
      margin: $space_md--sm 0;

      @include media-breakpoint-up(lg) {
        margin: $space_md--lg 0;
      }

      @include media-breakpoint-up(xl) {
        margin: $space_md--xl 0;
      }
    }

    &--sm {
      margin: $space_sm--sm 0;

      @include media-breakpoint-up(lg) {
        margin: $space_sm--lg 0;
      }

      @include media-breakpoint-up(xl) {
        margin: $space_sm--xl 0;
      }
    }

    &--xs {
      margin: $space_xs--sm 0;

      @include media-breakpoint-up(lg) {
        margin: $space_xs--lg 0;
      }
    }
  }

  &__mt {

    &--xxl {
      margin-top: $space_xxl !important;
    }

    &--xl {
      margin: $space_xl--sm;

      @include media-breakpoint-up(lg) {
        margin-top: $space_xl--lg;
      }

      @include media-breakpoint-up(xl) {
        margin-top: $space_xl--xl;
      }
    }

    &--lg {
      margin-top: $space_lg--sm;

      @include media-breakpoint-up(lg) {
        margin-top: $space_lg--lg;
      }

      @include media-breakpoint-up(xl) {
        margin-top: $space_lg--xl;
      }
    }

    &--md {
      margin-top: $space_md--sm;

      @include media-breakpoint-up(lg) {
        margin-top: $space_md--lg;
      }

      @include media-breakpoint-up(xl) {
        margin-top: $space_md--xl;
      }
    }

    &--sm {
      margin-top: $space_sm--sm;

      @include media-breakpoint-up(lg) {
        margin-top: $space_sm--lg;
      }

      @include media-breakpoint-up(xl) {
        margin-top: $space_sm--xl;
      }
    }

    &--xs {
      margin-top: $space_xs--sm;

      @include media-breakpoint-up(lg) {
        margin-top: $space_xs--lg;
      }
    }
  }

  &__mb {

    &--xl {

      margin-bottom: $space_xl--sm;

      @include media-breakpoint-up(lg) {
        margin-bottom: $space_xl--lg;
      }

      @include media-breakpoint-up(xl) {
        margin-bottom: $space_xl--xl;
      }
    }

    &--lg {
      margin-bottom: $space_lg--sm;

      @include media-breakpoint-up(lg) {
        margin-bottom: $space_lg--lg;
      }

      @include media-breakpoint-up(xl) {
        margin-bottom: $space_lg--xl;
      }
    }

    &--md {
      margin-bottom: $space_md--sm;

      @include media-breakpoint-up(lg) {
        margin-bottom: $space_md--lg;
      }

      @include media-breakpoint-up(xl) {
        margin-bottom: $space_md--xl;
      }
    }

    &--sm {
      margin-bottom: $space_sm--sm;

      @include media-breakpoint-up(lg) {
        margin-bottom: $space_sm--lg;
      }

      @include media-breakpoint-up(xl) {
        margin-bottom: $space_sm--xl;
      }
    }

    &--xs {
      margin-bottom: $space_xs--sm;

      @include media-breakpoint-up(lg) {
        margin-bottom: $space_xs--lg;
      }
    }
  }

  &__py {

    &--xl {
      padding: $space_xl--sm 0;

      @include media-breakpoint-up(lg) {
        padding: $space_xl--lg 0;
      }

      @include media-breakpoint-up(xl) {
        padding: $space_xl--xl 0;
      }
    }

    &--lg {
      padding: $space_lg--sm 0;

      @include media-breakpoint-up(lg) {
        padding: $space_lg--lg 0;
      }

      @include media-breakpoint-up(xl) {
        padding: $space_lg--xl 0;
      }
    }

    &--md {
      padding: $space_md--sm 0;

      @include media-breakpoint-up(lg) {
        padding: $space_md--lg 0;
      }

      @include media-breakpoint-up(xl) {
        padding: $space_md--xl 0;
      }
    }

    &--sm {
      padding: $space_sm--sm 0;

      @include media-breakpoint-up(lg) {
        padding: $space_sm--lg 0;
      }

      @include media-breakpoint-up(xl) {
        padding: $space_sm--xl 0;
      }
    }

    &--xs {
      padding: $space_xs--sm 0;

      @include media-breakpoint-up(lg) {
        padding: $space_xs--lg 0;
      }
    }
  }

  &__pt {

    &--xl {
      padding-top: $space_xl--sm;

      @include media-breakpoint-up(lg) {
        padding-top: $space_xl--lg;
      }

      @include media-breakpoint-up(xl) {
        padding-top: $space_xl--xl;
      }
    }

    &--lg {
      padding-top: $space_lg--sm;

      @include media-breakpoint-up(lg) {
        padding-top: $space_lg--lg;
      }

      @include media-breakpoint-up(xl) {
        padding-top: $space_lg--xl;
      }
    }

    &--md {
      padding-top: $space_md--sm;

      @include media-breakpoint-up(lg) {
        padding-top: $space_md--lg;
      }

      @include media-breakpoint-up(xl) {
        padding-top: $space_md--xl;
      }
    }

    &--sm {
      padding-top: $space_sm--sm;

      @include media-breakpoint-up(lg) {
        padding-top: $space_sm--lg;
      }

      @include media-breakpoint-up(xl) {
        padding-top: $space_sm--xl;
      }
    }

    &--xs {
      padding-top: $space_xs--sm;

      @include media-breakpoint-up(lg) {
        padding-top: $space_xs--lg;
      }
    }
  }

  &__pb {

    &--xl {
      padding-bottom: $space_xl--sm;

      @include media-breakpoint-up(lg) {
        padding-bottom: $space_xl--lg;
      }

      @include media-breakpoint-up(xl) {
        padding-bottom: $space_xl--xl;
      }
    }

    &--lg {
      padding-bottom: $space_lg--sm;

      @include media-breakpoint-up(lg) {
        padding-bottom: $space_lg--lg;
      }

      @include media-breakpoint-up(xl) {
        padding-bottom: $space_lg--xl;
      }
    }

    &--md {
      padding-bottom: $space_md--sm;

      @include media-breakpoint-up(lg) {
        padding-bottom: $space_md--lg;
      }

      @include media-breakpoint-up(xl) {
        padding-bottom: $space_md--xl;
      }
    }

    &--sm {
      padding-bottom: $space_sm--sm;

      @include media-breakpoint-up(lg) {
        padding-bottom: $space_sm--lg;
      }

      @include media-breakpoint-up(xl) {
        padding-bottom: $space_sm--xl;
      }
    }

    &--xs {
      padding-bottom: $space_xs--sm;

      @include media-breakpoint-up(lg) {
        padding-bottom: $space_xs--lg;
      }
    }
  }

  &__hr {
    border: 1px solid $white;
  }

  &__gutters {

    @include media-breakpoint-up(lg) {
      margin:0 0 0 -1.875rem;
    }

    &--inner {

      @include media-breakpoint-up(lg) {

        margin:0 0 0 1.875rem;
        flex:1 0 34%;
      }
    }
  }
}
