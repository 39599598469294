/* Flag CSS */
$flag-icon-css-path: '../../../globals/src/assets/images/flag-icons' !default;
@if variable-exists(env) {
  @if($env == 'production') {
    $flag-icon-css-path: '../images/flag-icons'
  }
}

$flag-icon-rect-path: '/4x3' !default;
$flag-icon-square-path: '/1x1' !default;

@import "~flag-icon-css/sass/flag-icon-base";
@import "~flag-icon-css/sass/flag-icon-list";
