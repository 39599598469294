.formroads {

  &__body {
    padding: 3.5rem;
  }

  &__btn {
    width: 100%;
  }

  &__textarea {
    @extend .form-control;

    padding: 12px 20px;
    margin: 8px 0;

    border: 1px solid $white;
    background-color: transparent;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $white;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: $white;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: $white;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: $white;
    }
  }

  &__input {

    height: 70px;
    width: 100%;

    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;

    color: white;

    border: 1px solid $white;
    background-color: transparent;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $white;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: $white;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: $white;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: $white;
    }
  }
}
