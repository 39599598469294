.highlights {

  overflow: hidden;
  border: none;

  /* Height Bug*/
  & > .carousel-item {
    height: 100%;

    & > .row {
      height: 100%;
    }

    & picture {
      width: 100%;
    }
  }

  &__content {

    &--right {

      padding: 1.875rem 1.875rem 4.875rem 1.875rem;
      height: 100%;

      @include media-breakpoint-up(lg) {
        padding: 1.875rem;
      }
    }
  }

  &__badge {
    position: absolute;

    padding: 0 1.2rem;

    top: 1.5rem;
    left: .5rem;

    justify-content: flex-start;
    z-index: $zindex-fixed;

    @include media-breakpoint-up(lg) {
      left: 1rem;
      width: 100%;
    }
  }

  &__carousel {

    height: 100%;
    width: 100%;
  }

  &__title {
    color: theme-color('primary');
    font-weight: normal;
    font-family: $font-family-sans-serif;
    font-size: 1.9rem;

    padding-bottom: 1.15rem;

    &:hover {
      text-decoration: underline;
      color: theme-color('primary');
    }
  }

  &__listicon {
    color: theme-color('primary');
  }

  &__paragraph {
    color: #000000;
  }

  &__inner {
    @extend .carousel-inner;

    margin: 0 auto;
    width: 90%;
    padding-top: 2rem;

    position: relative;

    @include media-breakpoint-up(lg) {
      height: 100%;
      padding-top: 0;
    }
  }

  &__item {
    @extend .carousel-item;
  }

  &__controls {

    position: absolute;
    bottom: 1.875rem;
    left: 1.875rem;

    @include media-breakpoint-up(lg) {

      bottom: 2rem;
      left: 2rem;
    }
  }

  &__control {

    opacity: 1;
    margin-right: 0.5rem;

    & > span {
      
      &:hover {
        background-color: $highlight_control_bg_hover;
      }

      padding: .75rem 1.25rem;
      height: inherit;

      background-color: $highlight_control_bg;

      color: $highlight_control_color;
      font-weight: 800;
    }
  }

}
