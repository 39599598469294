.achievements {

  &__prices {

    padding: 1.875rem;
    height: 10.75rem;

    @include media-breakpoint-up(lg) {
      padding: .5rem;
    }

    & > img {
      width: 100%;
      height: 100%;

      object-fit: contain;

      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      filter: grayscale(100%);
    }

    &--mobile {

      object-fit: contain;
      max-height: 30vh;
      height: 100%;

      padding: 1.3rem;

      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      filter: grayscale(100%);
    }
  }
}
