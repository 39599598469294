.hotspot-image__wrapper {
  display: inline-block;
  position: relative;
  z-index: 0;

  .hotspot-image__image {
    z-index: 0;
  }

  .hotspot-image__hotspot {
    position: absolute;
    width: 14px;
    height: 14px;
    background-color: white;
    border-radius: 1em;
    cursor: pointer;
    z-index: 1;
    box-shadow: 0 0 0 3px theme-color('ci-green-dark');
    animation: hotspot-pulse 1s infinite;
  }
}

.hotspot-image__tooltip {
  .tooltip-inner {
    background-color: theme-color('ci-green-dark');
    box-shadow: 2px 2px 10px rgba(0,0,0,.3);
    border-radius: 3px;
    font-size: 16px;
  }

  &.bs-tooltip-top {
    .arrow::before {
      border-top-color: theme-color('ci-green-dark');
    }
  }

  &.bs-tooltip-bottom {
    .arrow::before {
      border-bottom-color: theme-color('ci-green-dark');
    }
  }

  &.bs-tooltip-left {
    .arrow::before {
      border-left-color: theme-color('ci-green-dark');
    }
  }

  &.bs-tooltip-right {
    .arrow::before {
      border-right-color: theme-color('ci-green-dark');
    }
  }
}

@keyframes hotspot-pulse {
  0% {
      box-shadow: 0 0 0 3px theme-color('ci-green-dark');
  }
  50% {
      box-shadow: 0 0 0 6px theme-color('ci-green-dark');
  }
  100% {
      box-shadow: 0 0 0 3px theme-color('ci-green-dark');
  }
}
