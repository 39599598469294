.datasheet {

  &__padding {

    align-content: space-between;
    padding: 1.875rem 0 0 0;

    @include media-breakpoint-up(lg) {

      padding: 0 0 0 1.875rem;
    }
  }

  &__btn {

    z-index: $zindex-popover;
    width: 100%;

    &--overlay {

      padding: 1.25rem 2rem !important;  //FIX
      background-color: rgba(255,255,255,0.3);
      color: theme-color('primary-dark-90');

      &:hover {
        background-color: $white;
        color: theme-color('primary');
      }

    }
  }
}
