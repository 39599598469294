.product-banner {

  .carousel-item {

    &:hover {

      & > .overlay {
        display: block;
      }
      h4 {
        color: $color__white;
        text-shadow: 3px 3px 3px black;
      }

      & > img, picture {

        filter: blur(6px);
        -webkit-filter: blur(6px);
        -moz-filter: blur(6px);
        -o-filter: blur(6px);
        -ms-filter: blur(6px);
      }
    }

    position: relative;

    .overlay {

      display: none;

      width: 100%;
      height: 100%;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    img {
      height: 150px;
      width: auto;
    }
  }

  .carousel-indicators {
    margin: 0;

    & li {
      background-color: $color__dark-blue;
    }
  }

  span.fal {
    color: $color__dark-blue;
    font-size: 2.3rem;
  }
}
