@import '../components/Buttons/mixins';

@function rem($px) {
  @return ($px / $font-size-base) * 1rem;
}

@function em($px) {
  @return ($px / $font-size-base) * 1em;
}

// Only applied to bold weight text
@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin letter-spacing {
  letter-spacing: 0;
}

// Mixin Blocks
@mixin page--color($color) {
  color: $color;
}

@mixin section--items($color) {
  .section--headline {
    color: $color;
  }
}

@mixin block--feature($headline, $text, $icon) {
  .block--feature {

    h2,
    h3,
    h4,
    h5,
    h6,
    .block__headline {
      color: $headline;
    }

    .block__text {
      color: $text;
    }

    .block__icon {
      color: $icon;
    }
  }
}

@mixin block--card($headline, $text) {
  .block--card {

    h2,
    h3,
    h4,
    h5,
    h6,
    .block__headline {
      color: $headline;
    }

    .block__text {
      color: $text;
    }
  }
}

@mixin gradient__right--wave($bgColor, $startColor, $endColor, $img, $position, $attachment) {

  background-color: $bgColor;

  background: -webkit-linear-gradient(90deg, $startColor 0, $endColor 90%), url($img), -webkit-linear-gradient(90deg, $bgColor 0, $bgColor 100%);
  background: -moz-linear-gradient(90deg, $startColor 0, $endColor 90%), url($img), -moz-linear-gradient(90deg, $bgColor 0, $bgColor 100%);
  background: linear-gradient(90deg, $startColor 0, $endColor 90%), url($img), linear-gradient(90deg, $bgColor 0, $bgColor 100%);

  background-repeat: no-repeat;
  background-position: $position;

  background-attachment: $attachment;

  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;

  @include media-breakpoint-up(lg) {
    -ms-background-size: contain;
    -o-background-size: contain;
    -moz-background-size: contain;
    -webkit-background-size: contain;
    background-size: contain;
  }
}

@mixin gradient__right($bgColor, $startColor, $endColor) {

  background-color: $bgColor;

  background: -webkit-linear-gradient(90deg, $startColor 0, $endColor 90%), -webkit-linear-gradient(90deg, $bgColor 0, $bgColor 100%);
  background: -moz-linear-gradient(90deg, $startColor 0, $endColor 90%), -moz-linear-gradient(90deg, $bgColor 0, $bgColor 100%);
  background: linear-gradient(90deg, $startColor 0, $endColor 90%), linear-gradient(90deg, $bgColor 0, $bgColor 100%);
}
