/* Font Awesome */
$fa-font-path: "../../../globals/src/assets/fonts";
@if variable-exists(env) {
  @if($env == 'production') {
    $fa-font-path: "../fonts";
  }
}

@import "~@fortawesome/fontawesome-pro/scss/fontawesome";
@import "~@fortawesome/fontawesome-pro/scss/regular";
@import "~@fortawesome/fontawesome-pro/scss/solid";
@import "~@fortawesome/fontawesome-pro/scss/brands";
@import "~@fortawesome/fontawesome-pro/scss/light";
