.features {

  &__image {
    width: 100%;
  }

  &__header {
    height: 25rem;
  }

  &__nav {
    border: 2px solid $white;
  }

  &__border--blue {
    border-bottom: 2px solid theme-color('primary');
  }

  &__content {
    @extend .tab-content;

    margin: 1.5rem;
    color: #555555;
    overflow-x: auto;

    @include media-breakpoint-up(lg) {
      padding: 4rem;
      overflow-x: inherit;
    }
  }

  &__headline {
    //@extend .headings__primary;

    font-weight: normal;
    padding-bottom: 0.75rem;
  }

  &__list {
    font-size: 1.15rem;
    padding-left: 1rem;
  }

  &__show {
    color: theme-color('primary');
    font-size: 1.15rem;

    &:hover {
      text-decoration: underline;
      color: theme-color('primary');
    }

    &[aria-expanded="true"] > &--less {
      display: block;
    }

    &[aria-expanded="true"] > &--all {
      display: none;
    }

    &[aria-expanded="false"] > &--less {
      display: none;
    }

    &[aria-expanded="false"] > &--all {
      display: block;
    }
  }

  &__icon {

    @include media-breakpoint-up(lg) {
      width: 3rem;
    }
  }

  &__tab {

    width: 25%;
    height: 80px;

    font-size: 0;
    line-height: 1.2rem;

    justify-content: center;
    text-align: left;

    background-color: theme-color('primary');
    color: $white;

    border-bottom: 2px solid $white;

    @include media-breakpoint-up(lg) {
      height: 80px;
      width: 100%;
      font-size: 1.15rem;
      padding-left: 3.5rem;
      justify-content: start !important;
    }

    &.active {
      background-color: $white !important;
      color: theme-color('primary') !important;
    }

    &:hover {
      background-color: $white;
      color: theme-color('primary');
    }
  }

  &__table {
    @extend .table;

    margin: 0 auto;

    &--paragraph {
      margin: 0;
    }

    &--content {
      text-align: center;
    }

    &--even {
      background-color: theme-color('primary-25');
      border-top: none;
    }

    tr {
      border-top: 1px solid #E8E8E8;
    }

    &--noborder td {
      border-top: none !important;
    }

    td, th {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }

    th {
      vertical-align: middle;

      font-size: 1.1rem;
      color: theme-color('primary');
    }
  }
}
