@mixin button-white-variant($color) {

  color: $color;

  background-color: $color__white;

  &:hover {
    background-color: $color;
    color: $color__white;

    -webkit-box-shadow:inset 0px 0px 0px 1px $color__white;
    -moz-box-shadow:inset 0px 0px 0px 1px $color__white;
    box-shadow:inset 0px 0px 0px 1px $color__white;
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }
}

@mixin button-outline-white-variant($color) {

  color: $color__white;

  background-color: transparent;
  border-color: $color__white;

  &:hover {
    background-color: $color__white;
    color: $color;

    border-color: $color__white;

    -webkit-box-shadow:inset 0px 0px 0px 1px $color__white;
    -moz-box-shadow:inset 0px 0px 0px 1px $color__white;
    box-shadow:inset 0px 0px 0px 1px $color__white;
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }
}
