$theme-colors: (
  "primary":          #0088C5,
  "primary-dark":     #005082,
  "primary-25":       rgba(0,136,198, 0.25),
  "primary-50":       rgba(0,136,198, 0.5),
  "primary-dark-50":  rgba(0,80,130, 0.5),
  "primary-dark-90":  rgba(0,80,130, 0.9),

  "secondary":        #EA6D0D,
  "secondary-dark":   #BE3200,
  "secondary-25":     rgba(234,109,13,0.25),
  "secondary-50":     rgba(234,109,13,0.5),
  "secondary-dark-50":rgba(190,50,0,0.5),
  "secondary-dark-90":rgba(190,50,0,0.9),

  "ci-blue":          #0088c6,
  "ci-blue-dark":     #005082,

  "ci-orange":        #EA6D0D,
  "ci-orange-dark":   #BE3200,

  "ci-yellow":        #FFD700,
  "ci-yellow-dark":   #FFAF00,

  "ci-violet":        #3C5AA0,
  "ci-violet-dark":   #1E417D,
  "ci-violet-25":     rgba(60,90,160,0.25),
  "ci-violet-50":     rgba(60,90,160,0.5),
  "ci-violet-dark-50":rgba(30,65,125,0.5),
  "ci-violet-dark-90":rgba(30,65,125,0.9),

  "ci-red":           #BE1441,
  "ci-red-dark":      #96193C,
  "ci-red-25":        rgba(190,20,65,0.25),
  "ci-red-50":        rgba(190,20,65,0.5),
  "ci-red-90":        rgba(190,20,65,0.9),
  "ci-red-dark-50":   rgba(150,25,60,0.5),
  "ci-red-dark-90":   rgba(150,25,60,0.9),

  "ci-green":         #C8D200,
  "ci-green-dark":    #A5AA1E,
  "ci-green-50":      rgba(200,210,0,0.5),
  "ci-green-dark-50": rgba(165,170,30,0.5),

  "ci-grey":          #555555,
  'ci-grey-light':    #f0f0f0,
);

// Round corner
$enable-rounded:      false;
$border-radius:       0;

// Body settings
$body-bg:             theme-color("primary");
$body-color:          $color__white;

// Link Settings
$link-hover-color:    theme-color("secondary");

// Headings
$headings-font-weight:        100;
$headings-color:              $color__white;

// Breadcrumps
$breadcrumb-divider-color:    $color__white;
$breadcrumb-active-color:     $color__white;
