/* CARDS */

.card {

  &__container {
    @extend .card;

    border: none;
  }

  &__deck {
    @extend .card-deck;

    display: flex;
    flex-direction: row;
    overflow-x: auto;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      overflow-x: inherit;
    }

    & > .card {
      min-width: 90vw;
      margin-right: 30px;

      @include media-breakpoint-up(lg) {
        min-width: inherit;
        margin-right: 15px;
      }

      &:last-child {
        margin-right: 0;

        @include media-breakpoint-up(lg) {
          margin-right: 15px;
        }
      }
    }

    &--noflow {
      @include media-breakpoint-down(lg) {
        flex-direction: column;
        overflow-x: inherit;
      }
    }

    -ms-overflow-style: none;  // Internet Explorer 10+
    scrollbar-width: none;  // Firefox

    &::-webkit-scrollbar {
      display: none;  // Safari and Chrome
    }

    @include media-breakpoint-up(lg) {
      overflow-x: inherit;
    }
  }

  &__padding {

    &--none {
      padding: 0;
    }

    &--md {
      padding: 1.2375rem;

      @include media-breakpoint-up(lg) {
        padding: 1.875rem;
      }
    }

    &--lg {
      padding: 1.2375rem;

      @include media-breakpoint-up(lg) {
        padding: 2.5rem;
      }
    }

    &--xl {
      padding: 1.2375rem;

      @include media-breakpoint-up(lg) {
        padding: 3.875rem;
      }
    }

    &--xxl {
      padding: 1.2375rem;

      @include media-breakpoint-up(lg) {
        padding: 3.3125rem;
      }

      @include media-breakpoint-up(xl) {
        padding: 5.3125rem;
      }
    }
  }

  &__title {

    &--company {
      font-weight: normal;
    }
  }

  &__header {
    @extend .card-header;
    @extend .bg__transparent;

    padding: 0;
  }

  &__body {
    @extend .card-body;
  }

  &__img {

    height: auto;
    width: 100%;

  /*&__img {

    position: relative;
    width: 100%;
    padding-top: 56.25%;
    height: 0;

    background-repeat: no-repeat;
    background-size: contain;

    @include media-breakpoint-up(lg) {

      padding: 0;
      height: 100%;

      background-size: cover;
      background-position: center center;
    }*/

    &--top {

      position: relative;
      width: 100%;
      padding-top: 56.25%; /* 16:9 Aspect Ratio */
      height: 0;

      background-repeat: no-repeat;
      background-size: contain;
    }

    &--overlay {

      position: absolute;

      bottom: 0;

      text-align: center;
      white-space: nowrap;

      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);

      padding: 0.625rem 0.8125rem 0.625rem 0.8125rem;

      background-color: theme-color('primary');
      color: $white;

      font-weight: bold;
    }

    &--startpage {
      @include media-breakpoint-up(lg) {
        height: 25rem;
      }
    }
  }
}
