.bg {
  &--contain {
    background-size: contain !important;
    background-position: center;
  }

  &--cover {
    background-size: cover !important;
    background-position: center;
  }
}
