#hero-header {

  .carousel-item {

    @include media-breakpoint-up(lg) {

      min-height: 53rem;
      height: calc(100vh);
    }

    height: 100vh;

    background: no-repeat top center scroll;
    background-size: cover;
  }

  .carousel-indicators {

    & li {

      @include media-breakpoint-up(lg) {
        height: 9rem;
        width: 15rem;

        margin-right: 1.25rem;
      }

      box-sizing: inherit;

      opacity: 1;
      text-indent: 0;

      height: 1.65rem;
      width: 2.75rem;

      border-top: none;
      border-bottom: none;
    }

    & > .active {

      &::before {

        position: absolute;

        content:"";

        top:0;
        left:0;

        width:100%;
        height:100%;

        z-index: 16;

        background-color: theme-color('ci-green');
        opacity: .75;
      }

      & > * {
        z-index: 17;
      }
    }
  }

  .hero-box {
    display: block;
    position: relative;

    padding: 0;

    .after {

      position: absolute;

      bottom: 1.475rem;
      right: 0.875rem;
    }
  }

  .hero-boxcontent {
    position: absolute;
    width: 100%;
    height: 100%;

    top: 0;
    left: 0;

    font-weight: bold;
    font-size: 1rem;
    color: $white;

    & > p {

      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    @include media-breakpoint-up(lg) {
      padding: 1.475rem 0.875rem;
    }
  }

  .hero-link {

    display: none;

    @include media-breakpoint-up(lg) {

      position: absolute;
      display: block;

      bottom: 1.475rem;
      right: 0.875rem;

      font-size: 1.5rem;

      z-index: 100;
    }
  }

  .hero-cta {
    color: white;

    font-family: $font-family-sans-serif;
    font-weight: bold;
  }
}
