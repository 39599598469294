//-------------------------
// 🖋 Fonts
//-------------------------

$font-path: '../../../src/globals/assets/fonts' !default;

@if variable-exists(env) {
  @if($env == 'production') {
    $font-path: '../fonts';
  }
}

@import 'import-once';

/* RBNo2.1b */
@mixin rbno21b-font-face {
  @font-face {
      font-family: 'RBNo2.1b';
      font-weight: 100;
      src: url('#{$font-path}/rb_light.woff2') format('woff2'),
      url('#{$font-path}/rb_light.woff') format('woff'),
      url('#{$font-path}/rb_light.eot') format('embedded-opentype'),
      url('#{$font-path}/rb_light.ttf') format('truetype');
  }

  @font-face {
      font-family: 'RBNo2.1b';
      font-weight: normal;
      src: url('#{$font-path}/rb_book.woff2') format('woff2'),
      url('#{$font-path}/rb_book.woff') format('woff'),
      url('#{$font-path}/rb_book.eot') format('embedded-opentype'),
      url('#{$font-path}/rb_book.ttf') format('truetype');
  }

  @font-face {
      font-family: 'RBNo2.1b';
      font-weight: bold;
      src: url('#{$font-path}/rb_bold.woff2') format('woff2'),
      url('#{$font-path}/rb_bold.woff') format('woff'),
      url('#{$font-path}/rb_bold.eot') format('embedded-opentype'),
      url('#{$font-path}/rb_bold.ttf') format('truetype');
  }
}

/* CenturyGothic */
@mixin centurygothic-font-face {
  @font-face {
      font-family: 'CenturyGothic';
      font-weight: normal;
      src: url('#{$font-path}/century.woff2') format('woff2'),
      url('#{$font-path}/century.woff') format('woff'),
      url('#{$font-path}/century.eot') format('embedded-opentype'),
      url('#{$font-path}/century.ttf') format('truetype');
  }

  @font-face {
      font-family: 'CenturyGothic';
      font-weight: bold;
      src: url('#{$font-path}/century_bold.woff2') format('woff2'),
      url('#{$font-path}/century_bold.woff') format('woff'),
      url('#{$font-path}/century_bold.eot') format('embedded-opentype'),
      url('#{$font-path}/century_bold.ttf') format('truetype');
  }
}
