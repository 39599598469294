.accordion {

  @extend .card;

  background-color: transparent;
  margin-bottom: 0.75rem;

  &__accordion {



    &--header {
      padding-bottom: 0.5rem;
    }

    &--body {
      padding: 0 1.25rem 1.75rem 0;
    }

    &--icon {

      align-self: center;
      padding-right: .5rem;
    }

    &--link {

      display: block;
      position: relative;

      color: $white;
      font-weight: normal !important;
      text-decoration: none !important;

      width: 100%;
      padding-right: 3.25rem;

      &:hover {
        text-decoration: underline !important;
        font-weight: normal !important;
        color: $white;
      }

      &:after {
        content: "\f078"; /* fa-chevron-down */

        font-family: 'Font Awesome 5 Pro';
        font-size: 2.25rem;
        font-weight: 900;
        line-height: 1;
        -webkit-font-smoothing: antialiased;

        transition: all 0.5s;

        position: absolute;
        right: .25rem;
        top: -.1rem;
      }

      &[aria-expanded="true"]:after {
        -webkit-transform: rotate(180deg);
	      -moz-transform: rotate(180deg);
	      transform: rotate(180deg);
      }
    }
  }
}
