a.navigation__dropdown {
  &--headline,
  &--title {
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
}
