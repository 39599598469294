/* Custom Bootstrap Code */
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins.scss';

$font-family-sans-serif:      "RBNo2.1b", Arial, sans-serif;
$font-family-secondary:       "CenturyGothic", Arial, sans-serif;

$font-family-base:            $font-family-secondary;

$enable-responsive-font-sizes: false;

/* increase the width of the grid lg and xl breakpoints */
/*$grid-breakpoints: (
  lg: 1024px,
  xl: 1366px
);*/

/* increase container width for the wider lg and xl breakpoints */
$container-max-widths: (
  lg: 1050px,
  xl: 1400px
);

// Spacer
$spacers: map-merge(
  (
    6: ($spacer * 3.5),
    7: ($spacer * 4),
    8: ($spacer * 5),
    9: ($spacer * 5.5)
  ),
  $spacers
);

// Buttons
$btn-padding-y:               1.375rem;
$btn-padding-x:               2.75rem;
$btn-font-family:             $font-family-secondary;
$btn-font-size:               1.125rem;
$btn-line-height:             1.125rem;
$btn-white-space:             nowrap;
$btn-font-weight:             400;

// Headings
$headings-font-family:        $font-family-sans-serif;

// Links
$link-color:                  $white;
$link-decoration:             none;
$link-hover-decoration:       underline;

// Breadcrump
$breadcrumb-divider:          quote(">");
$breadcrumb-bg:               inherit;

// Typography
$font-size-base:              1rem;
$h1-font-size:                $font-size-base * 4.5;
$h2-font-size:                $font-size-base * 3;
$h3-font-size:                $font-size-base * 2.25;
$h4-font-size:                $font-size-base * 1.5;
$h5-font-size:                $font-size-base * 1.15;
$h6-font-size:                $font-size-base * 1;
