.accordion {

  .card {
    border: none;
    margin-bottom: 0.75rem;
    background-color: transparent;
  }

  .card-header {
    border: none;
    background-color: transparent;
  }

  a.accordion-link {
    display: block;
    position: relative;

    font-weight: normal !important;
    text-decoration: none !important;

    width: 100%;
    padding-right: 2.25rem;

    &:hover {
      text-decoration: underline !important;
      font-weight: normal !important;
    }

    &:after {
      content: "\f078"; /* fa-chevron-down */

      font-family: 'Font Awesome 5 Pro';
      font-size: 2.25rem;
      font-weight: 900;
      line-height: 1;
      -webkit-font-smoothing: antialiased;

      transition: all 0.5s;

      position: absolute;
      right: 0;
      top: .25rem;
    }

    &[aria-expanded="true"]:after {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
}
