.configure-ct {

  background-color: #fff;

  &__padding {
    margin: 0 3rem;
  }

  &__headline {
    font-size: 3em;
    color: white;
  }

  &__paragraph {
    color: white;
  }
}
