a {
  font-weight: bold;
}

.btn {

  @extend .justify-content-center;
  @extend .align-items-center;
  @extend .d-inline-flex;

  text-decoration: none;
  font-weight: 800;

  & span {
    font-size: 1.5rem;
  }

  &.btn-filter {

    &:hover {
      color: $color__white;

      background-color: theme-color('ci-green');
    }

    @extend .d-inline-flex;

    background-color: $color__white;

    font-size: 1rem;
    padding: 0.5rem 0.5rem;

    @include media-breakpoint-up(lg) {
      padding: 0.7rem 1.8rem;
      font-size: 1.25rem;
    }

    font-weight: normal;
    font-family: $font-family-sans-serif;
    color: $button_text_color;

    &.active {

      &:hover {
        color: $button_text_color;
        font-weight: bold;

        background-color: $color__white;
      }

      color: $color__white;
      font-weight: bold;
    }
  }
}



@each $color, $value in $theme-colors {
  .btn-white-#{$color} {
    @include button-white-variant($value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-white-#{$color} {
    @include button-outline-white-variant($value);
  }
}
