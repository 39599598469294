#navigation-meta {
  .dropdown {

    & .flag-icon {
      font-size: 1em;
    }

    .dropdown-menu {
      margin: 0;
      min-width: inherit;
      text-align: right;
      border: none;

      @include media-breakpoint-up(lg) {
        // #1150 - Flag alignment (difference between prod & dev unclear)
        transform: translate3d(-13px, 0px, 0px) !important;
      }
    }

    .dropdown-item {
      padding: 0.25rem 0.75rem;
      color: $navigation_meta_color !important;
      text-decoration: none;
    }
  }
}
