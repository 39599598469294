input.form-control {

  &:focus {
    background-color: transparent;
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $forms_color;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $forms_color;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: $forms_color;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: $forms_color;
  }

  height: 60px;
  width: 100%;

  padding: 12px 20px;
  margin: 0;
  box-sizing: border-box;

  color: $forms_color;

  border: 1px solid $forms_border_color;
  background-color: transparent;
}

select.form-control {

  &:focus {
    background-color: transparent;
  }

  height: 60px;
  width: 100%;

  color: $forms_color;

  background-color: transparent;
  border: 1px solid $forms_border_color;
}

.input-checkbox {

  display: block;
  position: relative;
  padding-left: 2rem;

  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  & input {

    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ span {
    background-color: theme-color('white');
  }

  /* When the checkbox is checked, add a blue background */
  & input:checked ~ span {
    background-color: theme-color('secondary');
  }

  /* Show the checkmark when checked */
  & input:checked ~ span:after {
    display: block;
  }

  /* Create a custom checkbox */
  & span {

    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: $color__white;

    &::after {

      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;

      width: 5px;
      height: 10px;

      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

select.form-control {
  &-inline {
    display: inline;
    width: auto;
  }
}