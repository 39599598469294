.page {
  &__context--primary {
    background-color: $color__white;
  }
}

.bg-primary {
  color: $bg-primary-text-color;

  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    color: $bg-primary-headline-color;
  }

  a {
    &:not(.btn):not(.nav-link) {
      color: $bg-primary-link-color;
    }
  }
}

.bg-secondary {
  color: $bg-secondary-text-color;

  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    color: $bg-secondary-headline-color;
  }

  a {
    &:not(.btn):not(.nav-link) {
      color: $bg-secondary-link-color;
    }
  }
}

.bg-tertiary {
  color: inherit;

  a {
    &:not(.btn):not(.nav-link) {
      color: $bg-tertiary-link-color;
    }

    &.badge-outline {
      &:hover {
        color: $color__white;
      }
    }
  }
}

.bg-white,
.bg__white {
  color: $bg-white-text-color;

  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    color: $bg-white-headline-color;
  }

  a {
    &:not(.btn):not(.nav-link) {
      color: $bg-white-link-color;
    }
  }
}
