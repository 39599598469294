.img {
  &__card {
    &--sm {
      width: 100%;
      height: auto;
    }
  }

  &__cover {
    &--lg {
      @include media-breakpoint-up(lg) {
        height: 100%;
        width: 100%;

        object-fit: cover;
      }
    }
  }
}
