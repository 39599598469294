.section {

  &__gutters {

    @include media-breakpoint-up(lg) {
      margin: 0 0 0 -1.875rem;
    }

    &--inner {

      @include media-breakpoint-up(lg) {

        margin: 0 0 0 1.875rem;
        flex: 1 0 34%;
      }
    }
  }
}
