/* Backgrounds */

.bg {
  &__transparent {
    background-color: transparent;
  }

  &__white {
    background-color: $color__white;
  }

  &__green {
    background-color: theme-color('ci-green');

    &--gradient {
      background-image: linear-gradient(90deg, theme-color('ci-green'), theme-color('ci-green-dark'));
    }
  }

  &__red {
    background-color: theme-color('ci-red');

    &--gradient {
      background-image: linear-gradient(90deg, theme-color('ci-red'), theme-color('ci-red-dark'));
    }
  }

  &__yellow {
    background-color: theme-color('ci-yellow-dark');
  }

  &__violet {
    background-color: theme-color('ci-violet');

    &--gradient {
      background-image: linear-gradient(90deg, theme-color('ci-violet'), theme-color('ci-violet-dark'));
    }
  }

  &__orange {
    background-color: theme-color('ci-orange');

    &--gradient {
      background-image: linear-gradient(90deg, theme-color('ci-orange'), theme-color('ci-orange-dark'));
    }
  }

  &__grey {
    background-color: #f0f0f0;
  }

  &__primary {
    background-color: theme-color('primary');

    &--gradient {
      background: linear-gradient(90deg, theme-color("primary") 50%, theme-color("primary-dark") 90%);
    }
  }

  &__secondary {

    background-color: theme-color('ci-orange');

    &--gradient {
      background: linear-gradient(90deg, theme-color("ci-orange"), theme-color("ci-orange-dark"));
    }
  }
}
