$fontevo: true;

// Production Env
$img-fon-path: '../../../src/fontevo/assets/';
@if variable-exists(env) {
  @if $env == production {
    $img-path: '../';
  }
}

// Navigation
// Navigation
$navigation_height: 						6.5rem;
$navigation_height_responsive: 	4.125rem;

$navigation_bg:  								$color__white;
$navigation_mobile_bg:          $color__orange;

$navigation_meta_bg: 						$color__white;
$navigation_meta_color:					$color__middle-grey;

$navigation_mobile_divider:     $color__blue;
$navigation_mobile_color:       $color__white;

$navigation_text_color:  				$color__white;
$navigation_text_color__white:  $color__blue;

$navigation_submenu_bg:         $color__orange;
$navigation-submenu-hr:         $color__orange-dark;

// Footer
$footer_color:									$color__white;
$footer_subline_color:					$color__white;

//Breadcrumb
$breadcrumb_color:              $color__white;

//Forms
$forms_border_color:            $color__white;
$forms_color:                   $color__white;

//Button
$button_text_color:             $body-color;

//Badges
$badge_bg:                      $color__white;
$badge_color:                   $color__middle-grey;
$badge_bg_hover:                $color__green;
$badge_color_hover:             $color__white;

$badge_outline_color:           $color__white;
$badge_outline_color_hover:     $body-color;
$badge_outline_bg_hover:        $color__white;

$badge_highlight_bg:            $color__green;
$badge_highlight_color:         $color__white;

$badge_new_bg:                  $color__orange;
$badge_new_color:               $color__white;

$badge_threeSixty_bg:           transparent;
$badge_threeSixty_color:        $color__white;

//Highlight
$highlight_control_bg:          $color__green;
$highlight_control_color:       $color__white;
$highlight_control_bg_hover:    theme-colors('ci-green-dark');

//Productsfilter
$productfilter_bg:              $color__blue;
$productfilter_button_bg:       $color__white;
$productfilter_button_color:    $color__middle-grey;

$productfilter_buttonactive_bg: $color__green;
$productfilter_buttonactive_color: $color__white;

//Service SUPPORT
$service_filter_input_placeholder: $color__grey;
$service_filter_input_border:   $color__blue;
$service_filter_result_bg:      $color__light-grey;
$service_filter_result_color:   $color__dark-grey;
$service_filter_headlines:      $color__dark-grey;
$support_container_bg:          $color__white;




// Deprecated
// Buttons
$button_height: 65px;
$button_filter_height: 50px;

$navigation_height: 7.75rem;
$navigation_height_responsive: 4.125rem;

$content-border: 1.25rem;

$primary-text-color: #555555;
$mobile-icon-color: #bcbcbc;

$border-color: rgba(0,80,130,.3);

$bg-primary-text-color: $color__white;
$bg-primary-link-color: $color__white;
$bg-primary-headline-color: $color__white;

$bg-secondary-text-color: $color__white;
$bg-secondary-link-color: $color__white;
$bg-secondary-headline-color: $color__white;

$bg-tertiary-text-color: $color__white;
$bg-tertiary-link-color: $body-color;
$bg-tertiary-headline-color: $color__white;

$bg-white-text-color: $color__middle-grey;
$bg-white-headline-color: $color__middle-grey;
$bg-white-link-color: $color__middle-grey;
